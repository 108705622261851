<template>
  <div id="app">
    <div v-show="isLearning ? isLoggedIn && !$route.meta.auth : !$route.meta.auth" class="app__bar">
      <k-navbar />

      <k-learning-bar v-if="isLearning" />
    </div>

    <div
      v-if="showMessage"
      class="servicing-message"
      :class="{
        servicingMessageAlert: adminMessageUrgency === 'alert',
        servicingMessageInfo: adminMessageUrgency === 'info'
      }"
    >
      <span id="admin-message-tag-id" />
      <button @click="markAsRead">x</button>
    </div>
    <k-loading v-if="loading" />
    <router-view v-else />
    <k-footer v-show="isLearning ? isLoggedIn && !$route.meta.auth : !$route.meta.auth" />
    <k-toast />
    <k-toast-list />
    <k-logged-out-modal
      :show="!isLoginPage && showLogoutModal"
      :is-logged-in="isLoggedIn"
      :is-user-session-expired="isUserSessionExpired"
    />
  </div>
</template>
<script>
import KNavbar from '@/components/Navbar'
import KLearningBar from '@/components/LearningBar'
import KFooter from '@/components/Footer'
import KLoading from '@/components/Loading'
import KLoggedOutModal from '@/components/LoggedOutModal'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isLearning } from '@/utils/learning-platform'
import api from './utils/api'
import { verifyUserSession } from '@/utils'
import LoanMixin from '@/mixins/loan'

export default {
  name: 'App',
  components: {
    KNavbar,
    KLearningBar,
    KFooter,
    KLoading,
    KLoggedOutModal
  },
  mixins: [LoanMixin],
  data() {
    return {
      showMessage: false,
      merchant_message_id: '',
      isLoginPage: false,
      isLearning
    }
  },
  computed: {
    ...mapGetters({
      loading: 'getAppLoadingStatus',
      adminMessage: 'getAdminMessage',
      adminMessageUrgency: 'getAdminMessageUrgency',
      adminMessageId: 'getAdminMessageId',
      showLogoutModal: 'events/getShowLogoutModal',
      merchant: 'auth/getUser',
      adminEmail: 'getAdminEmail',
      isLoggedIn: 'auth/getAuthState',
      userToken: 'auth/getToken'
    }),
    isUserSessionExpired() {
      const { userSessionHasExpired } = verifyUserSession(this.userToken ?? '')
      return userSessionHasExpired
    }
  },
  watch: {
    adminMessage: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.showMessage = true
          this.stringToHTML()
        } else {
          this.showMessage = false
        }
      }
    },
    $route: {
      handler: function (to) {
        document.title = to.meta.title || 'Kafene'
        this.isLoginPage = to.name === 'login'

        if (this.isLoggedIn) {
          window?.userpilot?.reload()
        }
        if (this.isLoggedIn && this.isUserSessionExpired) this.updateLogoutModal(true)
      },
      immediate: true
    },
    '$route.meta.auth': {
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.callMessageHistory()
        }
      }
    },
    showMessage(val) {
      if (!val) {
        this.setAdminMessage('')
      }
    },
    isLoginPage: {
      handler(val) {
        if (val) {
          this.updateLogoutModal(false)
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchPageData()
    if (this.isLoggedIn && window.userpilot?.identify) {
      const user = this.merchant
      if (user) {
        const [role] = user.user_role || []
        window.userpilot.identify(user.id, {
          user_role: role,
          name: user.name,
          email: user.email,
          company: { id: user.merchant_id },
          employee_id: role === 'E' ? user.employee_id : undefined
        })
        window?.userpilot?.reload()
      }
    }
  },
  mounted() {
    this.setFullStoryValues(this.adminEmail)
    this.callMessageHistory()
  },
  methods: {
    ...mapMutations(['setAdminMessage']),
    ...mapActions({
      updateLogoutModal: 'events/updateShowLogoutModal',
      fetchPageData: 'fetchPageData'
    }),
    stringToHTML() {
      //the nextTick function allows the DOM to be updated before the HTML is rendered -- added this to avoid undefined value
      this.$nextTick(() => {
        document.getElementById('admin-message-tag-id').innerHTML = this.adminMessage
      })
      this.setLinkToTargetBlank()
    },
    setLinkToTargetBlank() {
      // I am setting a settimeout so that the tag is set before manipulating it to avoid undefined value
      setTimeout(() => {
        const parent = document.getElementById('admin-message-tag-id')
        //loop through memssage to check if we have more than one link
        const desendant = parent.children
        for (let index = 0; index < desendant.length; index++) {
          const element = desendant[index]
          if (element.localName === 'a') {
            element.target = '_blank' //[<a target="_blank"></a>]
          }
        }
      }, 1000)
    },
    callMessageHistory() {
      const payload = {
        merchant_id: this.merchant.merchant_id
      }
      api
        .post('merchants/message-history', payload)
        .then((response) => {
          if (response.status === 200) {
            const last_message = response.data.data.data[0]
            this.merchant_message_id = last_message && last_message.message_id
            this.$store.commit('setAdminMessage', last_message && last_message.message_text)
            this.$store.commit('setAdminMessageUrgency', last_message && last_message.urgency)
            this.$store.commit('setAdminMessageExpiresAt', last_message && last_message.expired_at)
          }
        })
        .catch((err) => {
          return err
        })
    },
    markAsRead() {
      const payload = {
        message_id: this.merchant_message_id || this.adminMessageId
      }
      api
        .put('merchants/messages', payload)
        .then((response) => {
          if (response.status === 200) {
            this.merchant_message_id = ''
          }
        })
        .catch((err) => {
          return err
        })
      this.showMessage = false
    },
    async setFullStoryValues(email) {
      if (email) {
        await window.FS.identify(this.merchant.id, {
          displayName: email + ' ' + 'as' + ' ' + this.merchant.name,
          email: this.merchant.email,
          merchantId: this.merchant.merchant_id,
          userType: 'merchant'
        })
      } else {
        await window.FS.identify(this.merchant.id, {
          displayName: this.merchant.name,
          email: this.merchant.email,
          merchantId: this.merchant.merchant_id,
          userType: 'merchant'
        })
      }
    },
    getLoginPage() {
      var urlParts = document.location.href.split('/')
      return '/' + urlParts[2] + '/' + urlParts[3] + '/Login'
    },
    async handleModalBtnClick() {
      if (this.showLogoutModal) {
        await this.$store.dispatch('auth/logout')
        await this.$store.dispatch('events/updateShowLogoutModal', false)
        await this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style lang="scss" src="@/styles/main.scss"></style>

<style lang="scss">
.app {
  &__bar {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}
</style>
